import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { IProfile } from '../models/profile';
import { AuthService } from './auth.service';
import { ICustomer } from '../models/customer';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../models/response';

export type CustomersResponse = { locations: ICustomer[]; totalRecords: number }

@Injectable({
  providedIn: 'root'
})
export class UserService {

  profileData$ = new BehaviorSubject<IProfile | null>(null);
  _customersEndpoint = `${environment.apiBaseUrl}/user/v1/hr`;

  constructor(
    private _authService: AuthService,
    private _httpClient: HttpClient
  ) {
    this.getProfileData();
  }

  getProfileData() {
    localStorage.getItem('AUTH_DATA_KEY')
    const data = this._authService.getLoginData();
    if (data) this.profileData$.next(data);
  }

  getCustomers(params: any): Observable<CustomersResponse | null> {
    return this._httpClient
      .get<ApiResponse<CustomersResponse>>(this._customersEndpoint + `/customers`, { params, })
      .pipe(map((res => res.data)));
  }

}
