import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '/',
    },
    {
        id: 2,
        label: 'MENUITEMS.ORGANISATION.TEXT',
        icon: 'bx-buildings',
        subItems: [
            {
                id: 1,
                label: 'MENUITEMS.ORGANISATION.LIST.DEFAULT',
                link: '/organisation/details',
                parentId: 3
            },
            // {
            //     id: 2,
            //     label: 'MENUITEMS.ORGANISATION.LIST.MLA',
            //     link: '/organisation/mla',
            //     parentId: 3
            // },
            {
                id: 3,
                label: 'MENUITEMS.ORGANISATION.LIST.LOCATIONS',
                link: '/organisation/locations',
                parentId: 3
            },
        ]
    },
    {
        id: 3,
        label: 'MENUITEMS.EMPLOYEES.TEXT',
        icon: 'bx-group',
        link: '/employees',
    },
    {
        id: 4,
        label: 'MENUITEMS.SUBSCRIPTIONS.TEXT',
        icon: 'bx-car',
        link: '/subscriptions',
    },
    {
        id: 5,
        label: 'MENUITEMS.BILLINGS.TEXT',
        icon: 'bx-rupee',
        subItems: [
            {
                id: 1,
                label: 'MENUITEMS.BILLINGS.LIST.INVOICES',
                link: '/billings/invoices',
                parentId: 5
            },
            {
                id: 2,
                label: 'MENUITEMS.BILLINGS.LIST.CREDITNOTES',
                link: '/billings/credit-notes',
                parentId: 5
            },
            // {
            //     id: 2,
            //     label: 'MENUITEMS.BILLINGS.LIST.PAYMENTS',
            //     link: '/billings/payments',
            //     parentId: 5
            // },
        ]
    },
    // {
    //     id: 6,
    //     label: 'MENUITEMS.SETTINGS',
    //     icon: 'bx-cog',
    //     link: '/account/settings',
        
    // },
];
