import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/response';
import { ILocation } from '../models/locations';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { IOrganisationDetails } from '../models/corporate';
import { IAgreement } from '../models/agreement';

export type LocationResponse = { locations: ILocation[]; totalRecords: number }

export type LeaseAgreementResponse = { locations: IAgreement[]; totalRecords: number }

export type StatsResponse = { locationCount: number; activeSubscriptionCount: number; employeeCount: number }

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  organisationDetails$ = new BehaviorSubject<IOrganisationDetails | null>(null);

  constructor(
    private _httpClient: HttpClient
  ) {
    this.getOrganisationDetails();
  }

  _organisationEndpoint = `${environment.apiBaseUrl}/corporate/v1/hr`;

  getLeaseAgreements(params: any): Observable<LeaseAgreementResponse | null> {
    return this._httpClient
      .get<ApiResponse<LeaseAgreementResponse>>(this._organisationEndpoint + `/mla`, { params: params, })
      .pipe(map((res => res.data)));
  }

  getLocations(params: any): Observable<LocationResponse | null> {
    return this._httpClient
      .get<ApiResponse<LocationResponse>>(this._organisationEndpoint + `/locations`, { params: params, })
      .pipe(map((res => res.data)));
  }

  getLocationById(id: string): Observable<ILocation> {
    return this._httpClient
      .get<ApiResponse<ILocation>>(this._organisationEndpoint + `/locations/${id}`)
      .pipe(map((res => res.data)));
  }

  getOrganisationDetails() {
    this._httpClient
      .get<ApiResponse<IOrganisationDetails>>(this._organisationEndpoint + `/details`)
      .pipe(map((res => res.data))).subscribe({
        next: (res) => {
          this.organisationDetails$.next(res);
        }
      })
  }

  getSubscriptions(params: any): Observable<LocationResponse | null> {
    return this._httpClient
      .get<ApiResponse<LocationResponse>>(this._organisationEndpoint + `/subscriptions`, { params: params, })
      .pipe(map((res => res.data)));
  }

  getSubscriptionById(subscriptionId: any): Observable<LocationResponse | null> {
    return this._httpClient
      .get<ApiResponse<LocationResponse>>(this._organisationEndpoint + `/subscriptions/${subscriptionId}`,)
      .pipe(map((res => res.data)));
  }
  getOrganisationStats(): Observable<StatsResponse | null> {
    return this._httpClient
      .get<ApiResponse<StatsResponse>>(this._organisationEndpoint + `/stats`,)
      .pipe(map((res => res.data)));
  }
}
